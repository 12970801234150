/** 라이브 서버용 **/
export const redirectUrl = "https://netsmobility.com/reservation/verify";
export const PG_CARD = "nice_v2.IM0015960m";
export const PG_VBANK = "nice_v2.IM0015960m";

export const urls = {
  onSendAligo: "https://onsendaligo-ggrzcnz3fa-an.a.run.app",
  onSendEmail: "https://onsendemail-ggrzcnz3fa-an.a.run.app",
  onRemoveBook: "https://onremovebook-ggrzcnz3fa-an.a.run.app",
  onPostponeBook: "https://onpostponebook-ggrzcnz3fa-an.a.run.app",
  onPaymentCancel: "https://paymentcancel-ggrzcnz3fa-an.a.run.app",
  onGetCancelFee: "https://ongetcancelfee-ggrzcnz3fa-an.a.run.app",
  onFindCar: "https://onfindcar-ggrzcnz3fa-an.a.run.app",
  payments: "https://payments-ggrzcnz3fa-an.a.run.app",
  paymentCancel: "https://paymentcancel-ggrzcnz3fa-an.a.run.app",
  onChangeUserInfo: "https://onchangeuserinfo-ggrzcnz3fa-an.a.run.app",
};

export const firebaseConfig = {
  apiKey: "AIzaSyBMABsluBxCYU01g1fABANwxIqEeiA5YrQ",
  authDomain: "netsmobility-8a6bd.firebaseapp.com",
  projectId: "netsmobility-8a6bd",
  storageBucket: "netsmobility-8a6bd.appspot.com",
  messagingSenderId: "179380752292",
  appId: "1:179380752292:web:bdee2c76178ad82f47fb31",
  measurementId: "G-GJFJ6C89VB",
};

/** 테스트 서버용. 추후 테스트 서버를 다시 구축할 경우, 아래 필드를 채운 후 사용 **/
// export const redirectUrl = "";
// export const PG_CARD = "nice_v2.iamport00m";
// export const PG_VBANK = "nice_v2.iamport03m";

// export const urls = {
//   onSendAligo: "",
//   onSendEmail: "",
//   onRemoveBook: "",
//   onPostponeBook: "",
//   onPaymentCancel: "",
//   onGetCancelFee: "",
//   onFindCar: "",
//   payments: "",
//   paymentCancel: "",
//   onChangeUserInfo: "",
// };

// export const firebaseConfig = {
//   apiKey: "",
//   authDomain: "",
//   projectId: "",
//   storageBucket: "",
//   messagingSenderId: "",
//   appId: "",
//   measurementId: "",
// };
