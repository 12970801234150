import { Box } from "@mui/material";

export const InfoPricePage = () => {
  return (
    <Box sx={{ "& > img": { maxWidth: "100%" } }}>
      <img src={"/images/price_1.jpg"} alt="네츠 서비스 요금" />
      <img src={"/images/price_2.jpg"} alt="네츠 서비스 요금" />
      <img src={"/images/price_3.jpg"} alt="네츠 서비스 요금" />
    </Box>
  );
};
